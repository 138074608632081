import * as React from 'react';
import {
  useMotionValue,
  useSpring,
} from 'framer-motion';

import {
  Lang,
  Colors,

  getColorScheme,

  Text,
  Confetti,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../config/Setup.config';

import IconHandler from '../IconHandler';

export default function Statistic(props) {
  const { i18n } = Lang.useTranslation();
  const value = useMotionValue(0);
  const spring = useSpring(value, {
    duration: 1000,
    delay: 500,
  });

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;

  const [amount, setAmount] = React.useState(0);

  React.useEffect(() => {
    if (props.isInView) {
      value.set(props.data.amount);
    }
  }, [props.isInView]);

  React.useEffect(() => {
    spring.onChange((latest) => {
      setAmount(latest.toFixed(0));
    });
  }, [spring]);

  return (
    <>
      {
        props.data.icon && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 30,
              position: 'relative',
            }}
          >
            <IconHandler
              data={props.data.icon}
              height={120}
              width={120}
              color={props.changeContentColor ? Colors.WHITE : Colors.GREY}
            />
            <Confetti
              type={props.data.confetti.type}
              color={getColorScheme(props.data.confetti.color).vibrant}
              width={100}
              height={100}
              position="absolute"
              marginLeft={100}
              marginTop={15}
              zIndex={-1}
            />
          </div>
        )
      }
      <Text
        fontWeight={700}
        textAlign="center"
        fontSize="2em"
        color={props.changeContentColor ? Colors.WHITE : Colors.GREY}
      >
        {
          Intl.NumberFormat(lang, { maximumSignificantDigits: 3 })
            .format(amount)
        }
      </Text>
      {
        props.data.text && (
          <div
            style={{
              marginTop: 10,
            }}
          >
            <Text
              textAlign="center"
              portableText
              color={props.changeContentColor ? Colors.WHITE : Colors.GREY}
            >
              {props.data.text[lang]}
            </Text>
          </div>
        )
      }
    </>
  );
}

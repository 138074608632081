import BackgroundConfig from '../config/BackgroundHandler.config';

export default function handleContentColorWithBackground(background) {
  const getBackground = Object.keys(BackgroundConfig)
    .filter((item) => item === background)
    .map((item) => BackgroundConfig[item]);

  if (getBackground[0] === undefined) return false;

  return getBackground[0];
}

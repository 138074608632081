import * as React from 'react';

import {
  CustomIcon,
  Icon,
} from '@bluecurrent/web-component-lib';

export default function IconHandler(props) {
  const renderIcon = () => {
    switch (props.data.type) {
      case 'custom':
        return (
          <CustomIcon
            name={props.data.custom}
            height={props.height}
            width={props.width}
            color={props.color}
          />
        );
      case 'fa':
        return (
          <Icon
            name={props.data.fa}
            height={props.height}
            width={props.width}
            color={props.color}
            iconSet="FA"
          />
        );
      case 'feather':
        return (
          <Icon
            name={props.data.feather}
            height={props.height}
            width={props.width}
            color={props.color}
            iconSet="Feather"
          />
        );
      case 'blueCurrent':
        return (
          <Icon
            name={props.data.blueCurrent}
            height={props.height}
            width={props.width}
            color={props.color}
            iconSet="BlueCurrent"
          />
        );
      default:
        return <div />;
    }
  };

  return renderIcon();
}

import * as React from 'react';
import {
  motion,
  useInView,
} from 'framer-motion';

import {
  Lang,
  Breakpoint,
  Colors,

  useDimensions,

  Title,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../config/Setup.config';

import handleContentColorWithBackground from '../../../functions/handleContentColorWithBackground';
import handleHeadingPrioritization from '../../../functions/handleHeadingPrioritization';

import Statistic from './Statistic';

export default function Statistics(props) {
  const ref = React.useRef(null);

  const { i18n } = Lang.useTranslation();
  const size = useDimensions();
  const hasBackground = handleContentColorWithBackground(props.params.background);
  const isInView = useInView(ref);

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;
  const length = props.data?.array.length;

  const defaultWidth = () => {
    if (length <= 4) return '30%';
    if (length > 4) return '25%';
    return '100%';
  };
  const isLastItem = (index) => (
    size.width >= Breakpoint.sm
    && size.width < Breakpoint.lg
    && index + 1 === length
  );

  const [width, setWidth] = React.useState(defaultWidth());

  const widthHandler = () => {
    if (size.width >= Breakpoint.sm && size.width < Breakpoint.lg) return '47.5%';
    if (size.width < Breakpoint.sm) return '100%';
    return defaultWidth();
  };

  React.useEffect(() => {
    setWidth(
      widthHandler(),
    );
  }, [size]);

  return (
    <motion.div
      ref={ref}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 100,
      }}
      viewport={{
        once: true,
      }}
      style={{
        marginBottom: 80,
      }}
    >
      {
          props.data.title && (
            <div
              style={{
                marginBottom: 30,
              }}
            >
              <Title
                textAlign="center"
                fontFamily="Caveat"
                fontWeight={300}
                fontSize="2.8em"
                color={hasBackground.changeContentColor ? Colors.WHITE : Colors.GREY}
                heading={handleHeadingPrioritization(props.params.index)}
              >
                {props.data.title[lang]}
              </Title>
            </div>
          )
        }
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: '5%',
          paddingBottom: size.width < Breakpoint.lg ? 50 : 0,
        }}
      >
        {
            props.data?.array.map((item, index) => (
              <div
                /* eslint-disable-next-line no-underscore-dangle */
                key={item._key}
                style={{
                  width: isLastItem(index) ? '100%' : width,
                  marginTop: isLastItem(index) ? 30 : 0,
                  marginBottom: (
                    size.width < Breakpoint.sm
                    && index + 1 !== length
                  ) ? 50
                    : 0,
                }}
              >
                <Statistic
                  data={item}
                  isInView={isInView}
                  lang={lang}
                  changeContentColor={hasBackground.changeContentColor}
                />
              </div>
            ))
          }
      </div>
    </motion.div>
  );
}
